import { Button } from "./button";
import React from "react";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { base, baseSepolia } from "wagmi/chains";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0
    }
  }
});

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "aea162f85e70b25b8dd88ce0e57fb571";

// 2. Create wagmiConfig
const metadata = {
  name: "Login Talent Protocol",
  description: "",
  url: location.origin,
  icons: ["https://talentprotocol.com/images/favicon.jpg"]
};

const chains = [base, baseSepolia];
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableCoinbase: true,
  auth: {
    email: false, // default to true
    showWallets: true, // default to true
    walletFeatures: true // default to true
  }
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  allowUnsupportedChain: false,
  themeVariables: {
    "--w3m-z-index": 2000
  },
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
    "aba1f652e61fd536e8a7a5cd5e0319c9047c435ef8f7e907717361ff33bb3588",
    "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709"
  ]
});

export const SignInWalletButton = ({ text, redirect_url, railsContext }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Button text={text} redirect_url={redirect_url} requiredChain={railsContext?.contractsEnv == "production" ? base : baseSepolia} />
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default (props, railsContext) => {
  return () => <SignInWalletButton {...props} railsContext={railsContext} />;
};
