import React, { useState, useEffect } from "react";
import { EmailButton, EmailInput, EmailForm } from "./styled";
import debounce from "lodash/debounce";
import { validations } from "src/api/validations";
import { SignInWalletButton } from "src/components-v2/sign-in-wallet-button";

export const Welcome = ({ email, setEmail, next, contractsEnv, redirect_url }) => {
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);
  const [localEmail, setLocalEmail] = useState(email);
  const [emailError, setEmailError] = useState("");

  const onSubmit = e => {
    setEmail(localEmail);
    e.preventDefault();
    next();
  };

  const debouncedEmailLookup = debounce(() => {
    if (!localEmail || !(localEmail.includes("@") && localEmail.includes("."))) {
      return;
    }

    validations
      .validateEmail(localEmail)
      .then(({ data }) => {
        if (data.error) {
          setEmailError(data.error);
          setIsContinueDisabled(true);
        } else {
          setEmailError("");
          setIsContinueDisabled(false);
        }
      })
      .catch(() => {
        setIsContinueDisabled(false);
      });
  }, 400);

  useEffect(() => {
    if (localEmail.includes("@") && localEmail.includes(".")) {
      debouncedEmailLookup();
    }
  }, [localEmail]);

  return (
    <>
      <div className="input-form">
        <h4 className="h4">Create Talent Passport</h4>
        <p className="body-md">
          By continuing you agree and you've read the{" "}
          <a
            href="https://talentprotocol.notion.site/Terms-Conditions-ec060cb6c06e49a98f17d235d0835773"
            target="_blank"
            className="sign-up-link"
          >
            Terms & Conditions
          </a>
          .
        </p>
        <EmailForm onSubmit={onSubmit}>
          <EmailInput
            type="email"
            placeholder="Email"
            value={localEmail}
            onChange={e => setLocalEmail(e.target.value)}
            required
          />
          {emailError && <p className="email-error">{emailError}</p>}
          <EmailButton type="submit" disabled={isContinueDisabled}>
            Continue
          </EmailButton>
        </EmailForm>
        <p className="body-sm">
          Already have an account?{" "}
          <a href="/sign-in" className="sign-up-link">
            Sign in
          </a>
        </p>
        <div className="separator-row">
          <div className="separator"></div>
          <span className="separator-text">or</span>
          <div className="separator"></div>
        </div>
        <SignInWalletButton text={"Wallet Sign Up"} railsContext={{ contractsEnv: contractsEnv || "production" }} redirect_url={redirect_url} />
      </div>
    </>
  );
};
