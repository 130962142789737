import { ConnectWalletButton } from "./styled";
import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAccount, useDisconnect, useSignMessage, useSwitchChain } from "wagmi";
import { useWeb3Modal, useWeb3ModalEvents } from "@web3modal/wagmi/react";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "src/components/icons";

export const Button = ({ text, redirect_url, requiredChain }) => {
  const { signMessageAsync } = useSignMessage();
  const { address, isConnected, chainId } = useAccount();
  const { switchChain } = useSwitchChain();
  const [status, setStatus] = useState("idle");
  const [loading, setLoading] = useState(false);

  const getNonce = async address => {
    const nonce = await axios.post("auth/login_nonces", { address }).then(res => res.data);
    return nonce.nonce;
  };

  const login = useMutation({
    mutationFn: async signature => {
      const res = await axios.post("auth/wallet_login", {
        address: address?.toLowerCase(),
        signature,
        chain_id: chainId
      });
      return res;
    }
  });

  // const { connectAsync } = useConnect();
  const { open } = useWeb3Modal();
  const { disconnectAsync } = useDisconnect();
  const { data } = useWeb3ModalEvents();

  const handleConnectWallet = async () => {
    if (!address) {
      open();
      toast.error("Please connect your wallet first", {
        autoClose: 3000
      });
      return;
    }

    if (chainId != requiredChain.id) {
      toast.info("You must first switch to the Base network", {
        autoClose: 3000
      });
      await switchChain({ chainId: requiredChain.id });
    }

    try {
      const nonce = await getNonce(address);
      const message = `Sign in with Talent Protocol\nnonce: ${nonce}`;
      const signature = await signMessageAsync({ message });
      const response = await login.mutateAsync(signature);
      window.location.href = `${redirect_url}?auth_token=${response.data.auth_token}`;
    } catch (error) {
      toast.error(`An error occured while connecting wallet: ${error.response.data?.error || error.message}`, {
        autoClose: 3000
      });
      console.error(error);
      setLoading(false);
      setStatus("idle");
      disconnectAsync();
    }
  };

  useEffect(() => {
    if (data.event === "SELECT_WALLET" && status === "idle") {
      setStatus("connecting");
    } else if (["CONNECT_SUCCESS", "MODAL_CLOSE"].includes(data.event) && status === "connecting" && isConnected) {
      setStatus("signing");
      setLoading(true);
      handleConnectWallet();
    } else if (data.event === "CONNECT_ERROR" && status === "connecting") {
      setStatus("idle");
      console.error("Error connecting wallet");
    }
  }, [login, data, signMessageAsync, status, isConnected]);

  const handleLinkWallet = async () => {
    await disconnectAsync();
    open();
  };

  return (
    <ConnectWalletButton type="button" onClick={handleLinkWallet}>
      {loading ? <Spinner width={16} className="mx-4" /> : text}
    </ConnectWalletButton>
  );
};
