import React, { useMemo, useState } from "react";
import ThemeContainer from "src/contexts/ThemeContext";
import { Welcome } from "./components/welcome";
import { Password } from "./components/password";
import { Success } from "./components/success";
import { users } from "src/api";
import { toast } from "react-toastify";

const SignUpPage = ({ redirect_url, railsContext }) => {
  const { captchaKey, contractsEnv } = railsContext;
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(1);

  const utmSource = useMemo(() => {
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
    return searchParams.get("utm_source");
  }, []);

  const createUser = (password, captcha) => {
    users
      .createAccount({
        email,
        password,
        utmSource,
        captcha
      })
      .then(() => {
        setStep(3);
      })
      .catch(e => {
        console.log(e);
        toast.error(e.response.data.error);
      });
  };

  const renderedStep = () => {
    if (step === 1) {
      return <Welcome email={email} setEmail={setEmail} next={() => setStep(2)} contractsEnv={contractsEnv} redirect_url={redirect_url} />;
    }

    if (step === 2) {
      return (
        <Password
          next={createUser}
          captchaKey={captchaKey}
        />
      );
    }

    if (step === 3) {
      return <Success />;
    }
  };

  return renderedStep();
};

export default (props, railsContext) => {
  return () => (
    <ThemeContainer>
      <SignUpPage {...props} railsContext={railsContext} />
    </ThemeContainer>
  );
};
